import React, { useState, useEffect } from 'react';
import './Cart.scss';
import { useDispatch, useSelector } from 'react-redux';
import { changeCartVisibility } from '../../../redux/settingsRedux';
import { changeQuantity, removeFromCart } from '../../../redux/cartRedux';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import emptyCartImage from '../../../Graphics/EmptyCart.svg';
import { publicRequest } from '../../../requestMethods';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
const Cart = () => {
    const [showPanel, setShowPanel] = useState(true);
    const cartItems = useSelector((state) => state.cart.products);
    const storeID = useSelector((state) => state.store?.storeID);
    const total = useSelector((state) => state.cart.total);
    const showCart = useSelector((state) => state.settings.showCart);
    const [emptyCartMessage, setEmptyCartMessage] = useState(false);
    const [inventoryWarnings, setInventoryWarnings] = useState({});
    // const showCart = true
    const dispatch = useDispatch()
    const navigate = useNavigate();
    // console.log("cart Items", cartItems);
    const location = useLocation();
    //store_id and storeID are same
    const store_id = location.pathname.split("/")[1];
    const filteredCartItems = cartItems.filter(item => item.sellerID === store_id);

    const filteredTotal = filteredCartItems.reduce((sum, item) => sum + (item.unitPrice * item.qty), 0);

    const togglePanel = () => {
        setEmptyCartMessage(false)
        setShowPanel(!showPanel);
    };

    const addToCart = (item) => {
        // dispatch action to add item to cart
        togglePanel();
    };
    const handleCheckout = () => {
        if (filteredCartItems.length === 0) {
            setEmptyCartMessage(true);
            return;
        }

        const itemsString = filteredCartItems.map(item => `${item.productID}-${item.qty}`).join(',');
        dispatch(changeCartVisibility(!showCart));
        navigate(`/${store_id}/checkout/`);
    };

    const handleIncreaseQuantity = async (item) => {
        // console.log("item in cart", item);


        const inventory = await checkInventoryForItem(item);

        // console.log("inventory in  cart", inventory);
        // console.log("qty", inventory.quantity);

        if (item.qty + 1 <= inventory.quantity) {
            dispatch(changeQuantity({ _id: item._id, productID: item.productID, newQty: item.qty + 1 }));
        } else if (inventory.quantity === undefined) {
            dispatch(changeQuantity({ _id: item._id, productID: item.productID, newQty: item.qty + 1 }));
        }
        else if (inventory.prebook) {
            dispatch(changeQuantity({ _id: item._id, productID: item.productID, newQty: item.qty + 1 }));
        }
        else {
            // Optionally, show a snackbar or alert to inform the user
            alert("Cannot increase quantity beyond available stock.");

        }
    };
    const checkInventoryForItem = async (item) => {
        const productID = item.productID;
        const sellerID = item.sellerID;

        const inventoryResponse = await publicRequest.get(`/product/${sellerID}/${productID}/inventory/public`, {
            withCredentials: true,
        });

        return inventoryResponse.data.inventory;
    };
    const handleDecreaseQuantity = async (item) => {
        if (item.qty > 1) {
            dispatch(changeQuantity({ _id: item._id, productID: item.productID, newQty: item.qty - 1 }));
            const inventory = await checkInventoryForItem(item);
            if (item.qty - 1 > inventory.quantity) {
                setInventoryWarnings(prev => ({
                    ...prev,
                    [item._id]: `Warning: ${item.productName} quantity exceeds available stock.`
                }));
            } else {
                setInventoryWarnings(prev => {
                    const newWarnings = { ...prev };
                    delete newWarnings[item._id];
                    return newWarnings;
                });
            }
        } else {
            dispatch(removeFromCart(item._id));
        }
    };


    useEffect(() => {
        const checkInventory = async () => {
            const warnings = {};
            for (const item of filteredCartItems) {
                const productID = item.productID;
                const sellerID = item.sellerID;

                const inventoryResponse = await publicRequest.get(`/product/${sellerID}/${productID}/inventory/public`, {
                    withCredentials: true,
                });
                // console.log("inventoryResponse", inventoryResponse);

                if (inventoryResponse.data ) {
                    const inventory = inventoryResponse.data.inventory;

                    // Check if the cart quantity exceeds available stock

                    if (!inventory.inStock && !inventory.prebook) {
                        warnings[item.productID] = "The item is out of stock.";
                    } else if (item.qty > inventory.quantity && !inventory.prebook) {
                        // console.log("item", item);
                        warnings[item.productID] = `Warning: Quantity exceeds available stock for product.`;
                        // Adjust the quantity in the Redux store
                        // dispatch(changeQuantity({ _id: item._id, productID: item.productID, newQty: inventory.quantity }));
                    }
                    // if (item.qty > inventory.quantity) {
                    //     warnings[item._id] = `Warning: quantity exceeds available stock.`;
                    // }
                }
            }
            setInventoryWarnings(warnings);
        };

        if (filteredCartItems.length > 0) {
            checkInventory();
        }
    }, [filteredCartItems.length]);

    return (
        <div>
            {/* {showCart && ( */}
            <div className="cart-page">
                {showPanel && (
                    <div className={`cart-panel ${showCart ? '' : 'hide'}`}>

                        <div className="cart-panel-header">

                            <h1>Shopping Cart</h1>
                            {/* <h1 onClick={() => dispatch(changeCartVisibility(!showCart))}>X</h1> */}
                            <CloseIcon
                                onClick={() => {
                                    dispatch(changeCartVisibility(!showCart));
                                    setEmptyCartMessage(false);
                                }}
                            />
                        </div>
                        {/*  */}
                        <div className="cart-panel-items">
                            {filteredCartItems.length === 0 ? (
                                <div className="cart-empty">
                                    <img src={emptyCartImage} alt="Empty Cart" />
                                    <p>Your Cart is Empty!</p>
                                </div>
                            ) : (

                                filteredCartItems.map(item => (
                                    <div className='cart__item' key={item._id}>
                                        <div className='cart__item-image'>
                                            {item.image ? <img src={item.image} alt={item.productName} /> :

                                                (
                                                    <div className="no-image">
                                                        <CameraAltIcon style={{ fontSize: '30px', color: '#ccc' }} /> {/* Adjust size for a small icon */}
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className='cart__item-details'>
                                            <div className='cart__item-name'>
                                                {item.productName}

                                            </div>
                                            <div className='cart__item-price'>
                                                ₹{item.unitPrice}
                                                {item.isPreBook && <span className="prebook-note"> (Pre-Book)</span>}
                                            </div>
                                            <div className='cart__item-discount'>Discount: {item.discount ? item.discount : 0}</div>
                                            <div className='cart__item-quantity'>
                                                {/* <button onClick={() => {
                                                    if (item.qty > 1) {
                                                        dispatch(changeQuantity({ _id: item._id, productID: item.productID, newQty: item.qty - 1 }))
                                                    } else {
                                                        dispatch(removeFromCart(item._id))
                                                    }
                                                }}>-
                                                </button> */}

                                                <button onClick={() => handleDecreaseQuantity(item)}>-</button>
                                                <span>{item.qty}</span>
                                                {/* <button onClick={() => dispatch(changeQuantity({ _id: item._id, productID: item.productID, newQty: item.qty + 1 }))}>+</button> */}

                                                <button onClick={() => handleIncreaseQuantity(item)}>+</button>
                                                <button onClick={() =>
                                                    // console.log(item)
                                                    dispatch(removeFromCart(item._id))}
                                                >Remove</button>
                                            </div>
                                            {inventoryWarnings[item._id] && (
                                                <div className="inventory-warning">{inventoryWarnings[item._id]}</div>
                                            )}
                                        </div>
                                    </div>
                                )))}


                        </div>
                        <div className='cart__remarks'>

                            <div className='cart__subtotal'>
                                <div>Subtotal:</div>
                                <div>₹{filteredTotal}</div>
                            </div>
                            <div className="cart__button">

                                <button onClick={() => dispatch(changeCartVisibility(!showCart))} className='primaryButton-outline'>Continue Shopping</button>
                                <button onClick={handleCheckout} className='checkout-btn'>Checkout</button>
                            </div>
                            {emptyCartMessage && <div className="empty-cart-message">Your cart is empty. Please add items to proceed to checkout.</div>}
                        </div>
                    </div>
                )}
            </div>



        </div>
    )
}

export default Cart