// StoreDetails.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StoreSettingsForm from '../StoreSettings';
import './storeDetails.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { sellerRequest } from '../../../requestMethods';
import { fetchAndUpdateSellerData } from '../../../redux/apiCalls';
import PackageDimensionsForm from '../PackageDimension/PackageDimensionsForm';
import PaymentSettingsForm from '../PaymentSettingsForm/PaymentSettingsForm';
import CategoryManagement from '../Category/CategoryManagement';
import PolicyEditor from '../Policies/PolicyEditor';

const StoreDetails = () => {
    const sellerData = useSelector((state) => state.seller.sellerData);
    const { sellerID } = useSelector((state) => state.seller.sellerData);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('storeDetails')

    const dispatch = useDispatch();
    const [storeData, setStoreData] = useState({
        storeName: '',
        storeAbout: '',
        gst_tin: ''
    });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [showNewPackageForm, setShowNewPackageForm] = useState(false);
    const [newPackage, setNewPackage] = useState({});
    const [packageDimensions, setPackageDimensions] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);

    // Fetch seller and package data as before
    useEffect(() => {
        if (sellerData && sellerData.store && sellerData.store[0]) {
            setStoreData(sellerData.store[0]);
        }
        // Fetch package dimensions here as needed
        const fetchPackageDimensions = async () => {
            try {
                // Replace with the correct API endpoint for fetching package dimensions
                const response = await sellerRequest.get(`/package/${sellerID}`);
                if (response.data) {
                    setPackageDimensions(response.data); // Assuming response has 'packages' array
                }
                console.log("RES", response.data);

            } catch (error) {
                console.error('Error fetching package dimensions:', error);
            }
        };
        fetchPackageDimensions();

    }, [sellerData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setStoreData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleUpdate = async () => {
        console.log("storeData", storeData);
        setLoading(true);
        try {
            await sellerRequest.put(`/seller/store/${sellerData.sellerID}/${sellerData._id}`, storeData);
            dispatch(fetchAndUpdateSellerData({ sellerID }));
            setSnackbarMessage('Store details updated successfully!');
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Failed to update store details.');
            setSnackbarSeverity('error');
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const handlePackageChange = (e) => {
        const selectedPackageId = e.target.value;
        const foundPackage = packageDimensions.find((pkg) => pkg._id === selectedPackageId);
        setSelectedPackage(foundPackage || null);
    };

    const handlePackageInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedPackage((prevPackage) => ({
            ...prevPackage,
            [name]: value,
        }));
    };
    const handlePackageUpdate = async () => {
        console.log(selectedPackage);

        if (selectedPackage) {
            try {
                await sellerRequest.put(`/package/${sellerID}/${selectedPackage._id}`, selectedPackage);
                // Refresh package dimensions after adding new package
                const response = await sellerRequest.get(`/package/${sellerID}`);
                setPackageDimensions(response.data);
                setSnackbarMessage('Package updated successfully!');
                setSnackbarSeverity('success');
            } catch (error) {
                setSnackbarMessage('Failed to update package.');
                setSnackbarSeverity('error');
            } finally {
                setSnackbarOpen(true);
            }
        }
    };

    const handleDeletePackage = async () => {
        console.log(selectedPackage);

        if (selectedPackage) {
            try {
                await sellerRequest.delete(`/package/${sellerID}/${selectedPackage._id}`);
                // Refresh package dimensions after adding new package
                const response = await sellerRequest.get(`/package/${sellerID}`);
                setPackageDimensions(response.data);
                setSnackbarMessage('Package deleted successfully!');
                setSnackbarSeverity('success');
            } catch (error) {
                setSnackbarMessage('Failed to delete package.');
                setSnackbarSeverity('error');
            } finally {
                setSnackbarOpen(true);
            }
        }
    };

    const handleAddNewPackage = async () => {
        console.log("New Pack", newPackage);
        const updatedPackage = {
            ...newPackage,
            sellerID: sellerID
        };

        setLoading(true);
        try {
            // Replace with the correct API endpoint for adding new package dimensions
            const res = await sellerRequest.post(`/package/${sellerID}`, updatedPackage);
            console.log(res.data);

            // Refresh package dimensions after adding new package
            const response = await sellerRequest.get(`/package/${sellerID}`);
            setPackageDimensions(response.data); // Assuming response has 'packages' array
            setSnackbarMessage('New package added successfully!');
            setSnackbarSeverity('success');
            setTimeout(() => {
                setShowNewPackageForm(false);
            }, 3000);
        } catch (error) {
            console.log(error);

            setSnackbarMessage('Failed to add new package.');
            setSnackbarSeverity('error');
        } finally {
            setLoading(false);
            setSnackbarOpen(true);
        }
    };

    return (
        <>
            <div className="store-details-container">
                <h2 className="store-details-container__title">Store Details</h2>
                <div className="store-details-container__forms" style={{ display: 'flex', flexDirection: 'row' }}>
                    <StoreSettingsForm
                        storeData={storeData}
                        handleInputChange={handleInputChange}
                        handleUpdate={handleUpdate}
                    />
                    <PackageDimensionsForm
                        packageDimensions={packageDimensions}
                        selectedPackage={selectedPackage}
                        handlePackageChange={handlePackageChange}
                        handlePackageInputChange={handlePackageInputChange}
                        handlePackageUpdate={handlePackageUpdate}
                        handleAddNewPackage={handleAddNewPackage}
                        handleDeletePackage={handleDeletePackage}
                        showNewPackageForm={showNewPackageForm}
                        setShowNewPackageForm={setShowNewPackageForm}
                        newPackage={newPackage}
                        setNewPackage={setNewPackage}

                    />
                     {/* <CategoryManagement/>  */}
                  
                </div>
            </div>
            {/* <div className="settings-tabs">
                    <button onClick={() => setActiveTab('storeDetails')}>Store Details</button>
                    <button onClick={() => setActiveTab('shippingPolicy')}>Shipping Policy</button>
                    <button onClick={() => setActiveTab('cancellationPolicy')}>Cancellation Policy</button>
                </div> */}
            {activeTab === 'shippingPolicy' && <PolicyEditor policyType="shipping" />}
            {/* Loader and Snackbar */}
            {loading && (
                <Backdrop
                    open
                    sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default StoreDetails;
