import React, { useEffect, useState } from 'react';
import './SubscriptionPlans.scss';
import { useDispatch, useSelector } from 'react-redux';
import { sellerRequest } from '../../requestMethods';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { planFeatures, planLimits } from './planConfig';
import sellerRedux, { updateSubscriptionData } from '../../redux/sellerRedux';

// Assuming you have these mappings


// const plans = [
//   {
//     name: 'Free Plan',
//     price: '₹0',
//     period: '/ forever',
//     features: [
//       '1 Product',
//       '1 Warehouse',
//       'Custom shipping options',
//     ],
//     buttonText: 'Get Started',
//     isPopular: false,
//   },
//   {
//     name: 'Starter',
//     price: '₹299',
//     period: '/ month',
//     features: [
//       'Everything in Free Plan',
//       '5 Products',
//       '2 Warehouses',
//     ],
//     buttonText: 'Choose Plan',
//     isPopular: false,
//   },
//   {
//     name: 'Premium',
//     price: '₹499',
//     period: '/ month',
//     features: [
//       'Everything in Starter Plan',
//       '100 Products',
//       'Unlimited Warehouses',
//       'Shipping Provider integrations (Delhivery, Shiprocket)',
//       'Bulk Order processing',
//       'Announcement Bar',
//       'Direct Checkout feature',
//       'WhatsApp customer support',
//       'Order tracking for customers',
//     ],
//     buttonText: 'Choose Plan',
//     isPopular: true, // Highlighted as Most Popular
//   },
//   {
//     name: 'Infinity',
//     price: '₹999',
//     period: '/ month',
//     features: [
//       'Everything in Premium',
//       'Unlimited Products',
//       'Custom Domain',
//       'Payment Gateway integration',
//       'WhatsApp Business API integration',
//     ],
//     buttonText: 'Choose Plan',
//     isPopular: false,
//   },
// ];


const plans = [
  // {
  //   name: 'Free Plan',
  //   price: '₹0',
  //   period: '/ forever',
  //   features: [
  //     '1 Product',
  //     '1 Warehouse',
  //     'Custom shipping options',
  //   ],
  //   buttonText: 'Get Started',
  //   isPopular: false,
  // },
  {
    name: 'Premium',
    price: '₹499',
    period: 'Month',
    features: [

      '100 Products',
      'Up to 1000 Orders/month',
      'Unlimited Warehouses',
      'Shipping Provider integrations (Delhivery)',
      'Bulk Order processing',
      'Order tracking for customers',
      'Product Categories',
      'Analytics Dashboard',
      'Order Status update to customer WhatsApp',
      // 'Payment Gateway Integration',

    ],
    buttonText: 'Choose Plan',
    isPopular: true, // Highlighted as Most Popular
    firstMonthPrice: 20,
  },
  {
    name: 'Premium Plus',
    price: '₹999',
    offerPrice: '₹649',
    period: 'month',
    features: [
      'Everything in Premium',
      'Unlimited Products',
      'No Order Limit',
      'Payment Gateway Integration',
      'Inventory & Stock Management',
      'Coupon Code & Management',
      'Custom Domain (Coming Soon)',
      // 'Meta Tags',
      // 'WhatsApp Business API integration',
      'Store Testimonials (Coming Soon)',
      'Product Reviews (Coming Soon)',
    ],
    buttonText: 'Choose Plan',
    isPopular: false,
    isSpecialOffer: true, // New property to indicate special offer
    firstMonthPrice: 20,
  },
];

const SubscriptionPlans = () => {
  // const currentPlan = 'Free Plan'; 
  const sellerID = useSelector((state) => state.seller.sellerData.sellerID);
  const sellerData = useSelector((state) => state.seller.sellerData);
  const [currentPlan, setCurrentPlan] = useState('');
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const dispatch = useDispatch();
  const subscriptionData = useSelector((state) => state.seller?.subscriptionData);
  // console.log("subscriptionData from plans", subscriptionData);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false); // New loading state


  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setConfirmationOpen(true); // Open confirmation dialog
  };

  const handleConfirmPayment = async () => {
    setIsLoadingCheckout(true); 
    setConfirmationOpen(false);
    await handleSubmit(selectedPlan.name, selectedPlan.price, selectedPlan.firstMonthPrice); // Proceed to payment
    setIsLoadingCheckout(false);
  };

  const handleSubmit = async (planName, amount, firstMonthPrice) => {
    // console.log("planName", planName);
    // console.log("amount", amount);
    try {
      setLoading(true);

      //creating a subscriptn id from razoprpay
      const getSubscriptionData = await sellerRequest.post(`/subscription/create-razorpay-subscription/`, {
        plan: planName
      });
      // console.log("subscriptionResponse", getSubscriptionData.data.subscriptionResponse);
      const subscriptionID = getSubscriptionData.data.subscriptionResponse.id;
      // console.log("Subscription ID", subscriptionID);
      //if subscriptionID is not null, then open the razorpay checkout
      if (subscriptionID) {
        await openRazorpayCheckout(subscriptionID, amount, planName, firstMonthPrice);
      }
    } catch (error) {

    }

  }
  const updateSubscription = async (planName, subscriptionID, currentStart, currentEnd, shortUrl, authAttempts) => {
    // console.log("planName", planName);
    // console.log("subscriptionID", subscriptionID);
    // console.log("subscriptionData", subscriptionData);
    // console.log("currentStart", currentStart);
    // console.log("currentEnd", currentEnd);
    // console.log("shortUrl", shortUrl);
    // console.log("authAttempts", authAttempts);
    setLoading(true);

    try {
      const features = planFeatures[planName] || [];
      const { productLimit, orderLimit } = planLimits[planName] || { productLimit: 0, orderLimit: 0 };

      if (!subscriptionData) {
        // console.log("subscriptionData does not exist");
        const subscriptionNewResponse = await sellerRequest.post(`/subscription/create-subscription/${sellerID}`, {
          sellerID,
          subscriptionID,
          plan: planName,
          startDate: new Date(),
          endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)), // 1 month later
          currentStart: currentStart,
          currentEnd: currentEnd,
          shortUrl: shortUrl,
          authAttempts: authAttempts,
          billingCycle: 'Monthly', // assuming monthly for simplicity
          amount: parseFloat(plans.find(plan => plan.name === planName).price.replace('₹', '')), // get the price
          features,
          currency: 'INR',
          productLimit,
          orderLimit
        });

        // console.log("Created new subscription", subscriptionNewResponse.data);
        // Uncomment the following line if you want to update the global state
        dispatch(updateSubscriptionData(subscriptionNewResponse.data));

      } else {
        // console.log("subscriptionData already exists");
        const subscriptionUpdateResponse = await sellerRequest.put(`/subscription/update-subscription/${sellerID}`, {
          plan: planName,
          features,
          productLimit,
          orderLimit
        });
        // console.log("Updated subscription", subscriptionUpdateResponse.data);
        dispatch(updateSubscriptionData(subscriptionUpdateResponse.data));
      }

      setCurrentPlan(planName); // Update the state with the newly selected plan
      setSnackbarMessage('Plan updated successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error updating the subscription plan', error);
      setSnackbarMessage('Failed to update the subscription plan.');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true); // Show the snackbar after the operation
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  useEffect(() => {
    // console.log("subscriptionData from plans useEffect", subscriptionData);
    if (subscriptionData?.plan) {
      setCurrentPlan(subscriptionData.plan);
    }
  }, [subscriptionData]);

  // Utility function to format date
  const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const openRazorpayCheckout = async (subscriptionID, amount, planName, firstMonthPrice) => {
    console.log("firstMonthPrice", firstMonthPrice);
    const options = {
      // key: 'rzp_test_lSizqoqj3UmZTc', //test
      key: 'rzp_live_SETRD4E1GT4g6B', //live
      subscription_id: subscriptionID,
      amount: amount, // Amount in the smallest currency unit
      currency: 'INR',
      name: 'Prodinent Digital',
      description: 'Subscription Payment Prodinent Digital',
      handler: async function (response) {
        // Handle successful payment here
        console.log(response);
        const paymentId = response.razorpay_payment_id;
        const orderId = response.razorpay_order_id;
        const signature = response.razorpay_signature;

        try {
          const verifyPaymentResponse = await sellerRequest.post(`/subscription/verifyPayment/${sellerID}`, {
            paymentId,
            orderId,
            signature,
            sellerID: sellerID,
            // mode: "test",  //test or live
            mode: "live",  //test or live


          });

          // console.log("verifyPaymentResponse", verifyPaymentResponse);
          if (verifyPaymentResponse.data.status.status === "captured") {
            // console.log("Payment verified successfully");
            // console.log("Checking subscription with ID", subscriptionID);

            try {
              // Once the payment is completed, fetch subscription data again
              const subscriptionData_razorpay = await sellerRequest.post(`/subscription/fetch-subscription/${sellerID}/${subscriptionID}`);
              // console.log("RES: subscriptionData_razorpay", subscriptionData_razorpay.data);
              // console.log("RES: auth_attempts", subscriptionData_razorpay.data.auth_attempts);

              const { current_start, current_end, short_url, auth_attempts } = subscriptionData_razorpay.data;

              const updatedSubscriptionData = await updateSubscription(planName, subscriptionID, current_start, current_end, short_url, auth_attempts);

              // console.log("updatedSubscriptionData", updatedSubscriptionData);
            } catch (error) {
              console.error('Error fetching or updating subscription data', error);
            }
          }

        } catch (error) {

        } finally {
          setLoading(false);
        }


      },
      prefill: {
        name: sellerData.contactName,
        email: sellerData.email,
        contact: sellerData.contactNumber
      },
      theme: {
        color: '#000000'
      },
      modal: {
        ondismiss: function () {
          console.log("Modal dismissed");
          setLoading(false);
        }
      }
    };

    const rzp = new window.Razorpay(options);
    
    rzp.open();
  }

  return (
    <div className="subscription-plans">
      {currentPlan ? ( // Check if a plan is selected
        <div className="subscription-details">
          <h2>Your Subscription Details</h2>
          <p><strong>Plan:</strong> {subscriptionData?.plan}</p>
          <p><strong>Subscription ID:</strong> {subscriptionData?.subscriptionID}</p>
          <p><strong>Start Date:</strong> {formatDate(subscriptionData?.startDate)}</p>
          <p><strong>End Date:</strong> {formatDate(subscriptionData?.endDate)}</p>
          <p><strong>Billing Cycle:</strong> {subscriptionData?.billingCycle}</p>
          <p><strong>Auto Renew:</strong> {subscriptionData?.autoRenew ? 'Yes' : 'No'}</p>
          <p><strong>Amount:</strong> {subscriptionData?.amount} {subscriptionData?.currency}</p>
          <p><strong>Current Start Date:</strong> {formatDate(subscriptionData?.currentStart)}</p>
          <p><strong>Current End Date:</strong> {formatDate(subscriptionData?.currentEnd)}</p>
          <p><strong>Status:</strong> {subscriptionData?.status}</p>
          <strong>Features:</strong>
          <div>
            {subscriptionData?.features.map((feature, index) => (
              <p key={index} className="feature-item">{feature}</p>
            ))}
          </div>
        </div>
      ) : (
        <>
          <h1 className="subscription-plans__title">Choose Your Plan</h1>
          <div className="subscription-plans__container">
            {plans.map((plan, index) => (
              <div
                className={`plan-card ${plan.isPopular ? 'plan-card--popular' : ''} ${plan.name === currentPlan ? 'plan-card--current' : ''}`}
                key={index}
              >
                {plan.isPopular && <div className="plan-card__badge">Most Popular</div>}
                {plan.isSpecialOffer && <div className="plan-card__special-offer">Special Offer!</div>}
                <h2 className="plan-card__name">{plan.name}</h2>
                <div className="plan-card__price">
                  {plan.isSpecialOffer && (
                    <>
                      <span className="plan-card__price-value plan-card__price-value--strikethrough">{plan.price}</span>
                      <span className="plan-card__price-value">{plan.offerPrice}</span>
                    </>
                  )}
                  {!plan.isSpecialOffer && (
                    <span className="plan-card__price-value">{plan.price}</span>
                  )}
                  <span className="plan-card__price-period">/{plan.period}</span>
                </div>
                <ul className="plan-card__features">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="plan-card__feature">
                      {feature}
                    </li>
                  ))}
                </ul>
                <button className="plan-card__button"
                  disabled={plan.name === currentPlan}
                  onClick={() => handlePlanSelect(plan)}
                >
                  {plan.name === currentPlan ? 'Current Plan' : plan.buttonText}
                </button>
              </div>
            ))}
          </div>
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {confirmationOpen && (
        <div className="confirmation-modal">
          <div className="confirmation-modal__content">
            <h2>Confirm Your Plan</h2>
            {selectedPlan && (
              <div>
                <h3>{selectedPlan.name}</h3>
                <p>Price: <strong>{selectedPlan.price}</strong></p>
                <p>Period: <strong>{selectedPlan.period}</strong></p>
                <p className="confirmation-modal__message">
                  You will be charged <strong>{selectedPlan.price}</strong> now and then <strong>{selectedPlan.price}</strong> every month.
                </p>
              </div>
            )}
            <div className="confirmation-modal__actions">
              <button className="btn-cancel" onClick={() => setConfirmationOpen(false)}>Cancel</button>
              <button className="btn-confirm" onClick={handleConfirmPayment}>Subscribe Now</button>
            </div>
          </div>
        </div>
      )}

      {isLoadingCheckout && (
        <div className="loader-overlay">
          <div className="loader">Please wait...</div>
        </div>
      )}

    </div>
  );
};

export default SubscriptionPlans;
