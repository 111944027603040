import { sellerRequest } from "../../requestMethods";

export const openRazorpayCheckout = async (subscriptionID, amount, planName, sellerID) => {
    console.log("amount", amount);
    console.log("subscriptionID", subscriptionID);

    const options = {
        // key: 'rzp_test_lSizqoqj3UmZTc', 
        key: 'rzp_live_SETRD4E1GT4g6B', 
        subscription_id: subscriptionID,
        amount: amount, // Amount in the smallest currency unit
        currency: 'INR',
        name: 'Prodinent Digital',
        description: 'Subscription Payment',
        handler: async function (response) {
            // Handle successful payment here
            console.log(response);
            const paymentId = response.razorpay_payment_id;
            const orderId = response.razorpay_order_id;
            const signature = response.razorpay_signature;

            try {
                const verifyPaymentResponse = await sellerRequest.post('/subscription/verifyPayment', {
                    paymentId,
                    orderId,
                    signature,
                    sellerID: sellerID,
                    // mode: "test",  //test or live
                    mode: "live",  //test or live


                });
                console.log("verifyPaymentResponse", verifyPaymentResponse);
                if (verifyPaymentResponse.data.status.status === "captured") {
                    console.log("Payment verified successfully");
                    console.log("Checking subscription with ID", subscriptionID);

                    try {
                        // Once the payment is completed, fetch subscription data again from razorpay
                        const subscriptionData_razorpay = await sellerRequest.post(`/subscription/fetch-subscription/${sellerID}/${subscriptionID}`);
                        console.log("RES: subscriptionData_razorpay", subscriptionData_razorpay.data);
                        console.log("RES: auth_attempts", subscriptionData_razorpay.data.auth_attempts);

                        const { current_start, current_end, short_url, auth_attempts } = subscriptionData_razorpay.data;

                        const updatedSubscriptionData = await renewSubscription(planName, subscriptionID, current_start, current_end, sellerID);

                        // console.log("updatedSubscriptionData", updatedSubscriptionData);
                    } catch (error) {
                        console.error('Error fetching or updating subscription data', error);
                    }
                }

            } catch (error) {

            }


        },
        prefill: {
            name: 'Customer Name',
            email: 'customer@example.com',
            contact: '9999999999'
        },
        theme: {
            color: '#F37254'
        }
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
};

export const renewSubscription = async (planName, subscriptionID, current_start, current_end, short_url, auth_attempts, sellerID) => {
    try {
        const now = new Date();
        // const endDate = new Date(now.getTime() + 2 * 60 * 1000); // Set endDate to 2 minutes from now
        const endDate = new Date(new Date().setDate(new Date().getDate() + 1))

        const response = await sellerRequest.put(`/renew-subscription/${subscriptionID}/${sellerID}`, {

            currentStart: current_start,
            currentEnd: current_end,

        });

        console.log("Subscription renewed successfully", response.data);
        return response.data; // Return the updated subscription data
    } catch (error) {
        console.error("Failed to renew subscription", error);
        throw error; // Re-throw the error for further handling
    }
};