import React from 'react';
import './ConfirmationBox.scss'; // Import the CSS file

const ConfirmationBox = ({ heading, content, onConfirm, onCancel, cancelLabel, confirmLabel, headingColor = 'rgb(0 0 0)', buttonBgColor = '#f44336' }) => {
  return (
    <div className="confirmation-overlay" role="dialog" aria-labelledby="confirmation-heading" aria-modal="true">
      <div className="confirmation-box">
        <h2 id="confirmation-heading" style={{ color: headingColor }}>{heading}</h2>
        <p>{content}</p>
        <div className="button-container">
          <button className="cancel-button" onClick={onCancel} aria-label="Cancel action">{cancelLabel}</button>
          <button className="confirm-button" style={{ backgroundColor: buttonBgColor }} onClick={onConfirm} aria-label="Confirm action">{confirmLabel}</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationBox;