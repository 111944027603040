import React, { useEffect, useState } from 'react';
import './order.scss';
import { Bars } from 'react-loader-spinner';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { sellerRequest } from '../../requestMethods';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { generateShippingLabel } from './generateShippingLabel '
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {

  shipNow,
  calculateShippingCost,
  printLabel,
  checkStatus
} from './delhiveryServices';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import LinkIcon from '@mui/icons-material/Link';
import Tooltip from '@mui/material/Tooltip';
import { deleteOrder, cancelOrder, duplicateOrder, updateOrderStatus, updateOrderTotalWeight } from './orderOperations';
import ConfirmationBox from '../ConfirmationBox/ConfirmationBox';
import useConfirmation from '../ConfirmationBox/useConfirmation';

const Order = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const sellerData = useSelector((state) => state.seller.sellerData);
  const { sellerID } = useSelector((state) => state.seller.sellerData);
  const [newMode, setNewMode] = useState(false);
  const [productID, setProductID] = useState(null);
  const orderID = location.pathname.split("/")[2]
  const [orderData, setOrderData] = useState(null); // Track the action type and details
  const { isConfirmationVisible, showConfirmation, confirmationAction, setConfirmationVisible } = useConfirmation();

  const [errorMessage, setErrorMessage] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [API_KEY, setAPI_KEY] = useState('');
  const [warehouseID, setWarehouseID] = useState('');

  const [selectedWarehouseID, setSelectedWarehouseID] = useState('');
  const [warehouseWarning, setWarehouseWarning] = useState('');
  const [warehouseData, setWarehouseData] = useState(null);
  const warehouses_delhivery = sellerData.shippingServices.delhivery.wareHouses;
  const warehouses_customShipping = sellerData.shippingServices.customShipping.wareHouses;
  const warehouses_indiaPost = sellerData.shippingServices.indiaPost?.wareHouses;

  const [selectedShippingService, setSelectedShippingService] = useState('');

  const [editPackageOpen, setEditPackageOpen] = useState(false);
  const [customerData, setCustomerData] = useState()
  const [tempCustomerData, setTempCustomerData] = useState(null);

  const [shipmentDetails, setShipmentDetails] = useState({
    length: orderData?.package?.length || '',
    breadth: orderData?.package?.breadth || '',
    height: orderData?.package?.height || '',
    weight: orderData?.package?.weight || ''
  });

  const [tempShipmentDetails, setTempShipmentDetails] = useState({
    length: orderData?.package?.length || '',
    breadth: orderData?.package?.breadth || '',
    height: orderData?.package?.height || '',
    weight: orderData?.package?.weight || ''
  });

  const [editPriceOpen, setEditPriceOpen] = useState(false);
  const [tempOrderData, setTempOrderData] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [showAddProductOverlay, setShowAddProductOverlay] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [products, setProducts] = useState([]);


  const handleAddProduct = (product) => {
    console.log("product", product.image[0]);
    console.log("Product", tempOrderData.products);

    const newProduct = {
      _id: product._id,
      productID: product.productID,
      productName: product.title,
      unitPrice: product.price,
      qty: 1,
      image: product.image[0]?.src || '',
      sellerID: product.sellerID,
      profit: product.profit || 0 // Ensure profit is included
    };

    setTempOrderData((prevData) => {
      console.log("prevData", prevData);
      const existingProductIndex = prevData.products.findIndex(p => p.productID === newProduct.productID);

      if (existingProductIndex !== -1) {
        // Product already exists, update the quantity
        const updatedProducts = [...prevData.products];
        updatedProducts[existingProductIndex].qty += 1; // Increase quantity by 1

        const updatedAmount = prevData.amount + newProduct.unitPrice; // Update amount
        const updatedProfit = prevData.profit + newProduct.profit; // Update profit
        const updatedTotalWeight = prevData.shipment.totalWeight + newProduct.weight; // Update total weight

        return {
          ...prevData,
          products: updatedProducts,
          amount: updatedAmount,
          profit: updatedProfit, // Update profit
          totalOrderValue: updatedAmount + (prevData.deliveryCharge || 0), // Update total order value
          shipment: {
            ...prevData.shipment,
            totalWeight: updatedTotalWeight // Keep the same total weight
          }
        };
      } else {
        // Product does not exist, add it to the list
        const newAmount = prevData.amount + newProduct.unitPrice; // Update amount
        const newProfit = prevData.profit + newProduct.profit; // Update profit
        const newTotalWeight = (prevData.shipment.totalWeight || 0) + (product.productWeight || 0); // Update total weight

        return {
          ...prevData,
          products: [...prevData.products, newProduct], // Add the new product
          amount: newAmount, // Update amount
          profit: newProfit, // Update profit
          totalOrderValue: newAmount + (prevData.deliveryCharge || 0), // Update total order value
          shipment: {
            ...prevData.shipment,
            totalWeight: newTotalWeight // Update total weight in shipment
          }
        };
      }
    });
  };

  let screenWidth = 0;
  let screenHeight = 0;
  window.onresize = screen;
  screenWidth = window.innerWidth;
  screenHeight = window.innerHeight;
  function screen() {
    screenWidth = window.innerWidth;
    screenHeight = window.innerHeight;

    if (screenWidth <= 600) {
      //product edit list hide
      setIsCollapsed(true)
    }

  }
  const fetchProducts = async () => {
    setLoading(true);
    try {
      const response = await sellerRequest.get(`/product/${sellerID}`);
      setProducts(response.data);
      setFilteredProducts(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchTerm) {
      const filtered = products.filter(product =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products); // Reset to all products if search term is empty
    }
  }, [searchTerm, products]);


  const generateTrackingLink = () => {
    const baseUrl = window.location.origin;
    return `${baseUrl}/${sellerID}/track/${orderData.orderID}`;
  };

  const generateTrackingMessage = () => {
    const link = generateTrackingLink();
    const storeName = sellerData.storeName || 'Our Store';
    const customerName = customerData.name || 'Valued Customer';

    return `
  🛍️ *Order Tracking Information*
  
  Hello ${customerName}! 👋
  
  Thank you for your order from ${storeName}! 🎉
  
  📦 *Order ID:* ${orderData.orderID}
  🚚 *Status:* ${orderData.status}
  
  🔗 Track your order here:
  ${link}
  
  📞 For any queries, feel free to contact us.
  
  🙏 Thank you for shopping with us!
  We hope you love your purchase! ❤️
    `.trim();
  };

  const handleCopyLink = () => {
    const message = generateTrackingMessage();

    navigator.clipboard.writeText(message)
      .then(() => {
        setSnackbarMessage('Tracking message copied to clipboard');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch(err => {
        console.error('Failed to copy message: ', err);
        setSnackbarMessage('Failed to copy message');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleWhatsAppShare = () => {
    const message = encodeURIComponent(generateTrackingMessage());

    // Remove any non-digit characters from the phone number
    const cleanPhoneNumber = customerData.phone.replace(/\D/g, '');

    // Ensure the phone number starts with the country code (assuming Indian numbers)
    const phoneNumberWithCountryCode = cleanPhoneNumber.startsWith('91')
      ? cleanPhoneNumber
      : `91${cleanPhoneNumber}`;

    const whatsappUrl = `https://wa.me/${phoneNumberWithCountryCode}?text=${message}`;
    window.open(whatsappUrl, '_blank');
  };



  // Sample order data

  const fetchOrder = async () => {
    setLoading(true);
    try {
      // const response = await sellerRequest.get(`order/find/${sellerID}/${orderID}`, {
      //   withCredentials: true
      // });
      const response = await sellerRequest.get(`order/get/${sellerID}/${orderID}`, {
        withCredentials: true
      });

      console.log("res Order data", response.data);
      // console.log("Order data", response.data);
      setOrderData(response.data);
      setTempOrderData(response.data)
      if (response.data.warehouseID) {
        // console.log("warehouse ID", response.data.warehouseID);
        setSelectedWarehouseID(response.data.warehouseID)
      }
      // console.log("seller data data", sellerData);
      if (response.data.shippingService) {
        // setSelectedShippingService(response.data.shippingService);
        // console.log("111", selectedShippingService);
      } else if (sellerData.shippingServices.defaultProvider) {
        // console.log("Default", sellerData.shippingServices.defaultProvider);
        // setSelectedShippingService("hi")
      }
      if (response.data.shipment) {
        setShipmentDetails((prevDetails) => ({
          ...prevDetails,
          length: response.data.shipment.length || '',
          breadth: response.data.shipment.breadth || '',
          height: response.data.shipment.height || '',
          weight: response.data.shipment.totalWeight || ''
        }));

        setTempShipmentDetails((prevDetails) => ({
          ...prevDetails,
          length: response.data.shipment.length || '',
          breadth: response.data.shipment.breadth || '',
          height: response.data.shipment.height || '',
          weight: response.data.shipment.totalWeight || ''
        }));
      }

      try {//fetch customer data with customer ID
        const customerResponse = await sellerRequest.get(`customer/find/${sellerID}/${response.data.customerID}`, {
          withCredentials: true
        });
        console.log("Customer data", customerResponse.data);
        setCustomerData(customerResponse.data);
        setTempCustomerData(customerResponse.data);


      } catch (error) {
        console.error('Error fetching order:', error);
      }
      setLoading(false);
      // setEditSection("price");
    } catch (error) {
      console.error('Error fetching order:', error);
      setOrderData(null);
      setLoading(false);
    }
  };
  const getAPI_KEY = async () => {
    try {
      const API_KEY_RES = await sellerRequest.get(`seller/find/${sellerID}`, {
        withCredentials: true
      });
      // console.log("API KEY", API_KEY_RES.data.shippingServices.delhivery.API_KEY);
      setAPI_KEY(API_KEY_RES.data.shippingServices.delhivery.API_KEY)

    } catch (error) {
      console.error('Error fetching API Key:', error);

    }
  }
  const fetchWarehouseData = (warehouses, warehouseID) => {
    // console.log("WH", warehouses);
    // console.log("ID", warehouseID);
    const warehouse = warehouses.find(warehouse => warehouse._id === warehouseID);
    if (!warehouse) {
      throw new Error('Warehouse not found');
    }

    console.log("WH Selected:", warehouse);
    setWarehouseData(warehouse);

    setWarehouseWarning(null)
  };
  useEffect(() => {
    fetchOrder();
    getAPI_KEY()

  }, [sellerID, orderID]);

  useEffect(() => {
    // console.log("use Effect selectd WH", selectedWarehouseID);
    // console.log("sevice", selectedShippingService);
    if (selectedShippingService === "Delhivery" && selectedWarehouseID) {

      // console.log("----------", selectedShippingService);
      fetchWarehouseData(warehouses_delhivery, selectedWarehouseID);
    } else if (selectedShippingService === "Custom Shipping" && selectedWarehouseID) {
      // console.log("XXX", selectedShippingService);
      fetchWarehouseData(warehouses_customShipping, selectedWarehouseID);
    }

  }, [selectedWarehouseID, warehouses_delhivery, warehouses_customShipping, warehouses_indiaPost]);


  const steps = ['Placed', 'Confirmed', 'Processing', 'Manifested', 'Shipped', 'Delivered'];
  const editableStatuses = ['Order Placed', 'Order Confirmed', 'Order Processing'];
  const getOrderStatusStep = (status) => {
    // console.log("status-", status);
    switch (status) {
      case 'Order Placed':
        return 0;
      case 'Order Confirmed':
        return 1;
      case 'Order Processing':
        return 2;
      case 'Manifested':
        return 3;
      case 'Shipped':
        return 4;
      case 'In Transit':
        return 4;
      case 'Delivered':
        return 5;
      default:
        return 0;
    }
  };

  // Order Placed  Order Confirmed  Order Processing  Manifested Shipped Delievered 
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

    const formattedDate = date.toLocaleDateString('en-US', optionsDate);
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

    return `${formattedDate} ${formattedTime}`;
  };
  const handleBack = () => {
    navigate(-1); // This will navigate to the previous page in history
  };


  const handleCheckStatus = async () => {//delhivery courier status check
    console.log("orderData", orderData);
    console.log("sellerData", sellerData);
    console.log("API_KEY", API_KEY);

    await checkStatus(orderData, sellerID, API_KEY, setOrderData,
      setLoading, setSnackbarMessage, setSnackbarSeverity)
  }
  useEffect(() => {
    console.log("selectedWarehouse ID | 324 ", selectedWarehouseID);

  }, [selectedWarehouseID])
  const handleUpdateOrderStatus = async (newStatus) => {
    // console.log("selctd WH", selectedWarehouseID);
    // console.log("Seller Data", sellerData);

    const currentWarehouseID = selectedWarehouseID;
    const currentShippingService = selectedShippingService;
    console.log("Selected WH ID", currentWarehouseID);
    console.log("Shipping service", currentShippingService);
    console.log("Order Data", orderData);

    if (!currentWarehouseID || !currentShippingService) {

      setWarehouseWarning("Select a Warehouse.", currentShippingService)
      return
    } else {

      await updateOrderStatus(orderData, sellerID, newStatus, selectedWarehouseID, selectedShippingService, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen, setOrderData, setWarehouseWarning);
      setWarehouseWarning(null)
    }
  };






  //Cancel Order
  const handleCancelOrder = async () => {

    // await cancelOrder(orderData, sellerID,
    //   setOrderData, setLoading, setSnackbarMessage,
    //   setSnackbarSeverity, setSnackbarOpen);

    try {
      const confirmed = await showConfirmation("Cancel Order Confirmation", "Are you sure you want to cancel this order?", "No", "Yes ");
      if (confirmed) {
        setLoading(true)
        await cancelOrder(orderData, sellerID, setOrderData, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen);
      }
    } catch (error) {
      console.log("Order cancellation was canceled.");
    } finally {
      setLoading(false)
    }
  };
  // Duplicate Order
  const handleDuplicateOrder = async () => {
    try {
      const confirmed = await showConfirmation("Order Clone Confirmation",
        "Are you sure you want to make a clone of this order?",
        "Cancel",
        "Yes"

      );
      if (confirmed) {
        await duplicateOrder(orderData, sellerID, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen);
      }
    } catch (error) {
      console.log("Order cancellation was canceled.");
    }

  };
  // Delete Order
  const handleDeleteOrder = async () => {

    try {
      const confirmed = await showConfirmation("Delete Order Confirmation", "Are you sure you want to delete this order?", "Cancel", "Yes, Delete");
      if (confirmed) {
        setLoading(true)
        await deleteOrder(orderData, sellerID, setLoading, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen, handleBack);
      }
    } catch (error) {
      console.log("Order cancellation was canceled.");
    } finally {
      setLoading(false)
    }

  };

  const handleShipNow = () => {
    // console.log("Seller Data", sellerData);
    shipNow(orderData, customerData, warehouseData, sellerID, sellerData.store[0].gst_tin,
      API_KEY, setOrderData, setLoading,
      setSnackbarMessage, setSnackbarSeverity, setErrorMessage);
  }
  const handleCalculateShippingCost = () => {
    // console.log("Cu", customerData);
    // console.log("WH", warehouseData);
    // console.log("Seller Data", sellerData);

    calculateShippingCost(orderData, sellerID, customerData, warehouseData, API_KEY,
      setOrderData, setLoading, setSnackbarMessage, setSnackbarSeverity);
  }


  const handlePrintLabel_Delhivery = () => {
    printLabel(orderData, API_KEY, setLoading);
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleWarehouseChange = (e) => {
    console.log("handle WH Change", e.target.value);

    setSelectedWarehouseID(e.target.value);
  };
  const handleShippingServiceChange = (e) => {
    console.log("000");
    setSelectedShippingService(e.target.value);
    setSelectedWarehouseID(''); // Reset warehouse selection on shipping service change
  };
  const handlePrintLabel_customShipping = () => {
    generateShippingLabel(orderData, warehouseData, sellerData);
  };

  useEffect(() => {
    // console.log(orderData);
    if (orderData) {

      // if (sellerData?.shippingServices?.defaultProvider && !orderData.shippingService) {
      //   console.log("Seller defaultProvider:", sellerData.shippingServices.defaultProvider);
      // }
      if (!orderData.shippingService) {

        setSelectedShippingService(sellerData.shippingServices.defaultProvider);
      } else {
        setSelectedShippingService(orderData.shippingService);

      }
    }
  }, [orderData]);

  useEffect(() => {
    // setting default WH
    console.log("Selected Shipping Service", selectedShippingService);
    if (selectedShippingService) {
      let defaultWarehouse = null;
      if (selectedShippingService === 'Delhivery') {
        if (orderData.warehouseID) {
          defaultWarehouse = sellerData.shippingServices.delhivery.wareHouses.find(wh => wh._id === orderData.warehouseID);
        } else {
          defaultWarehouse = sellerData.shippingServices.delhivery.wareHouses.find(wh => wh.isDefault);
        }
      } else if (selectedShippingService === 'Custom Shipping') {
        if (orderData.warehouseID) {
          defaultWarehouse = sellerData.shippingServices.customShipping.wareHouses.find(wh => wh._id === orderData.warehouseID);
        } else {
          defaultWarehouse = sellerData.shippingServices.customShipping.wareHouses.find(wh => wh.isDefault);
        }
      } else if (selectedShippingService === 'India Post') {
        if (orderData.warehouseID) {
          defaultWarehouse = sellerData.shippingServices.indiaPost.wareHouses.find(wh => wh._id === orderData.warehouseID);
        } else {
          defaultWarehouse = sellerData.shippingServices.indiaPost.wareHouses.find(wh => wh.isDefault);
        }
      }
      console.log("Default Warehouse", defaultWarehouse);
      if (defaultWarehouse) {
        console.log("Default Warehouse ID", defaultWarehouse?._id);
        setSelectedWarehouseID(defaultWarehouse?._id);
      }

    }
  }, [selectedShippingService])

  useEffect(() => {

    if (selectedShippingService === "Delhivery" && selectedWarehouseID) {

      // console.log("Delhivery", selectedShippingService);
      fetchWarehouseData(warehouses_delhivery, selectedWarehouseID);
    } else if (selectedShippingService === "Custom Shipping" && selectedWarehouseID) {
      // console.log("Custom Shipping", selectedShippingService);
      fetchWarehouseData(warehouses_customShipping, selectedWarehouseID);
    }

  }, [selectedShippingService])



  useEffect(() => {
    // console.log("selectedShippingService CHANGE", selectedShippingService);
  }, [selectedShippingService])

  const renderActiveShippingServices = () => {
    // console.log("1", sellerData.shippingServices);
    const services = [];

    // Check if shippingServices exists and is an object
    if (sellerData.shippingServices && typeof sellerData.shippingServices === 'object') {
      if (sellerData.shippingServices.indiaPost?.isActive) {
        services.push({ name: 'India Post', value: 'India Post' });
      }
      if (sellerData.shippingServices.delhivery?.isActive) {
        services.push({ name: 'Delhivery', value: 'Delhivery' });
      }
      if (sellerData.shippingServices.shiprocket?.isActive) {
        services.push({ name: 'Shiprocket', value: 'Shiprocket' });
      }
      if (sellerData.shippingServices.customShipping?.isActive) {
        services.push({ name: 'Custom Shipping', value: 'Custom Shipping' });
      }
      // Check if customShipping is an array and if any custom shipping service is active
      if (Array.isArray(sellerData.shippingServices.customShipping) && sellerData.shippingServices.customShipping.some(cs => cs.isActive)) {
        services.push({ name: 'Custom Shipping', value: 'customShipping' });
      }
    }

    return services.map((service, index) => (
      <option key={index} value={service.value}>{service.name}</option>
    ));
  };
  const handlePackageChange = (e) => {
    const { name, value } = e.target;
    setShipmentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };
  const [editSection, setEditSection] = useState(null);

  const handleEdit = (section) => {
    setEditSection(section);
  };

  const handleClosePackageEdit = () => {
    setEditPackageOpen(false);
  };
  const handleSavePackageDetails = async () => {
    setLoading(true)
    // console.log("123");
    await updateOrderTotalWeight(
      orderData,
      sellerID,
      tempShipmentDetails,
      setLoading,
      setSnackbarMessage,
      setSnackbarSeverity,
      setSnackbarOpen,
      setOrderData
    );
    setShipmentDetails(tempShipmentDetails);
    setLoading(false)
    setEditPackageOpen(false)
    setTimeout(() => {
      handleEdit(null);
    }, 1500);
  }
  const handleWeightChange = (e) => {
    const value = e.target.value;

    // Update packageDetails only if the value is numeric
    if (/^\d*\.?\d*$/.test(value)) {
      setTempShipmentDetails((prevDetails) => ({
        ...prevDetails,
        weight: value
      }));
    }
  };


  const handleDimensionChange = (fieldName, e) => {
    const value = e.target.value;
    // Update packageDetails only if the value is numeric


    if (/^\d*\.?\d*$/.test(value)) {
      setTempShipmentDetails({ ...tempShipmentDetails, [fieldName]: value });
    }
  };



  const handleClosePriceEdit = () => {
    setEditPriceOpen(false);
  };

  const handleOpenPriceEdit = () => {
    setEditPriceOpen(true);
  };

  const downloadVCard = (customerData) => {
    // console.log("Customer data", customerData);
    // console.log("Product", orderData.products);
    const orderDate = new Date(orderData.createdAt).toLocaleDateString();
    const productsOrdered = orderData.products
      .map((product, index) => {
        // console.log(`Product at index ${index}:`, product); // Debugging log

        // Access the correct property name from the product object
        return `${index + 1}. ${product.productName || 'Unknown Product'}`; // Adding a fallback for undefined product names
      })
      .join(', ');

    const notes = `Products Ordered: ${productsOrdered}. Order Date: ${orderDate}`;



    const fullName = `${customerData.name} | ${orderData.orderID}`;
    const vCardData = `
  BEGIN:VCARD
  VERSION:3.0
  FN:${fullName}
  TEL:${customerData.phone}
  EMAIL:${customerData.email}
  ADR:;;${orderData.shippingAddress.fullAddress};${orderData.shippingAddress.city};${orderData.shippingAddress.state};${orderData.shippingAddress.pincode};${orderData.shippingAddress.country}
 NOTE:${notes}
  END:VCARD`;

    // Create a Blob from the vCard string
    const blob = new Blob([vCardData], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);

    // Create a download link and trigger it programmatically
    const link = document.createElement('a');
    link.href = url;
    link.download = `${customerData.name}.vcf`; // The .vcf extension for vCard files
    link.click();

    // Clean up and release the object URL
    window.URL.revokeObjectURL(url);
  };


  const handleOrderDataChange = (field, value) => {
    setTempOrderData((prevOrderData) => ({
      ...prevOrderData,
      shippingAddress: {
        ...prevOrderData.shippingAddress,
        [field]: value
      }
    }));
  };

  const handleCustomerChange = (field, value) => {
    const keys = field.split('.');
    if (keys.length > 1) {
      setTempCustomerData(prevState => ({
        ...prevState,
        shippingAddress: {
          ...prevState.shippingAddress,
          [keys[1]]: value
        }
      }));
    } else {
      setTempCustomerData(prevState => ({
        ...prevState,
        [field]: value
      }));
    }
  };

  useEffect(() => {
    // console.log("Temp Customer Data", tempCustomerData);

  }, [tempCustomerData])

  const handleSaveCustomerDetails = async () => {
    setLoading(true)
    // console.log('Customer data to be saved:', tempCustomerData);
    const updatedCustomerData = {
      customerID: customerData.customerID,
      email: tempCustomerData.email,
      name: tempCustomerData.name,
      phone: tempCustomerData.phone,
      address: {
        street: tempOrderData.shippingAddress.street,
        city: tempOrderData.shippingAddress.city,
        state: tempOrderData.shippingAddress.state,
        pincode: tempOrderData.shippingAddress.pincode,
        country: tempOrderData.shippingAddress.country
      }
    };
    try {
      // Send a PUT request to the customer API endpoint
      const response = await sellerRequest.put(`customer/${sellerID}/${customerData._id}`, updatedCustomerData);
      // console.log('Customer information updated successfully:', response.data);
      await updateOrderShippingAddress(tempOrderData.shippingAddress);

      setSnackbarMessage('Customer information updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setCustomerData(tempCustomerData)
      setOrderData(tempOrderData);
      // setCustomerData({
      //   ...customerData,
      //   ...tempCustomerData,
      //   address: { ...updatedCustomerData.address }
      // });




      setLoading(false)
      // console.log("handle edit", handleEdit);
      setTimeout(() => {
        handleEdit(null);
      }, 1000);


    } catch (error) {
      console.error('Error updating customer information:', error);
      setSnackbarMessage('Customer information updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setLoading(false)
    }
  };

  useEffect(() => {
    // console.log("Customer data", customerData);

  }, [customerData])

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  // const [updatedOrderData, setUpadatedOrderData] = useState(orderData);

  //starting heere

  //changing qty. calculate profit and price

  const handleProductQuantityChange = async (index, event) => {
    console.log("handleProductQuantityChange");
    if (!tempOrderData) {
      console.error('Temporary order data is not available.');
      return;
    }
    
    console.log("1");
    console.log("2");
    const updatedOrderData = { ...tempOrderData };
    const inputValue = event.target.value;

    // Check if the input value is empty
    if (inputValue === "") {
      // Update the tempOrderData to reflect the empty input
      updatedOrderData.products[index].qty = "";
      updatedOrderData.products[index].totalPrice = 0;
      updatedOrderData.products[index].totalProfit = 0;

      setTempOrderData(updatedOrderData);
      return;
    }

    const newQty = Number(inputValue);

    if (Array.isArray(updatedOrderData.products) && updatedOrderData.products[index]) {
      if (newQty <= 0) {
        const confirmDelete = window.confirm("Do you want to delete this product?");
        if (confirmDelete) {
          updatedOrderData.products.splice(index, 1);
        } else {
          const prevQty = tempOrderData.products[index].qty;
          setTempOrderData({
            ...updatedOrderData,
            products: updatedOrderData.products.map((product, i) =>
              i === index ? { ...product, qty: prevQty } : product
            )
          });
          return;
        }
      } else {
        const productID = updatedOrderData.products[index].productID;
        console.log("3");
        try {
          const response = await sellerRequest.get(`/product/profit/${productID}/${sellerID}`);
          console.log("4", response.data);
          const profitPerItem = response.data.profit || 0;
          const totalProfit = profitPerItem * newQty;

          updatedOrderData.products[index].qty = newQty;
          updatedOrderData.products[index].totalPrice = newQty * updatedOrderData.products[index].unitPrice;
          updatedOrderData.products[index].totalProfit = totalProfit;
        } catch (error) {
          console.error('Error fetching profit:', error);
        }
      }

      // Recalculate amount, totalOrderValue, and profit
      let newAmount = 0;
      let newTotalOrderValue = 0;
      let newProfit = 0;

      updatedOrderData.products.forEach(product => {
        newAmount += product.totalPrice || (product.qty * product.unitPrice);
        newProfit += (product.qty * (product.totalProfit / product.qty || 0));
      });

      const deliveryCharge = Number(updatedOrderData.deliveryCharge) || 0;
      newAmount = newAmount || 0;
      newTotalOrderValue = newAmount + deliveryCharge;

      updatedOrderData.amount = newAmount;
      updatedOrderData.totalOrderValue = newTotalOrderValue;
      updatedOrderData.profit = newProfit;

      setTempOrderData(updatedOrderData);
    } else {
      console.error('Invalid index or products array');
    }
  };

  const updateOrderShippingAddress = async (shippingAddress) => {
    try {
      // Send a PUT request to update the order with the new shipping address
      const response = await sellerRequest.put(`order/update/${sellerID}/${orderData._id}`, {
        shippingAddress: shippingAddress
      });
      // console.log('Order shipping address updated successfully:', response.data);
    } catch (error) {
      console.error('Error updating order shipping address:', error);
      setSnackbarMessage('Failed to update order shipping address');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handlePriceChange = (field, e) => {
    const value = Number(e.target.value) || 0;

    setTempOrderData(prevData => {
      // Create a copy of previous data
      const updatedOrderData = { ...prevData, [field]: value };

      // Recalculate the total order value if necessary
      const newAmount = updatedOrderData.products.reduce((acc, product) =>
        acc + (product.totalPrice || (product.qty * product.unitPrice)), 0);

      // const newProfit = updatedOrderData.products.reduce((acc, product) =>
      //   acc + (product.qty * (product.totalProfit / product.qty || 0)), 0);

      const newProfit = updatedOrderData.products.reduce((acc, product) =>
        acc + (product.totalProfit || 0), 0);
      console.log("newProfit", newProfit);

      const deliveryCharge = updatedOrderData.deliveryCharge || 0;
      const newTotalOrderValue = field === 'totalOrderValue' ? value : newAmount + deliveryCharge;

      return {
        ...updatedOrderData,
        amount: newAmount,
        totalOrderValue: newTotalOrderValue,
        profit: newProfit

      };
    });
  };


  //updating price
  const handleSavePriceDetails = async () => {
    setLoading(true);
    // console.log('Price details to be saved:', tempOrderData);
    console.log("weight", tempOrderData.shipment.totalWeight);
    try {
      const response = await sellerRequest.put(`order/update/${sellerID}/${tempOrderData._id}`, {
        amount: tempOrderData.amount,
        deliveryCharge: tempOrderData.deliveryCharge,
        totalOrderValue: tempOrderData.totalOrderValue,
        profit: tempOrderData.profit,
        shipment: {
          totalWeight: tempOrderData.shipment.totalWeight
        },
        products: tempOrderData.products // Include products array
      });

      // console.log('Price details updated successfully:', response.data);
      setSnackbarMessage('Price details updated successfully');
      setSnackbarSeverity('success');
      handleClosePriceEdit(); // Close the overlay after saving
      setOrderData(tempOrderData)
      setTimeout(() => {
        handleEdit(null);
      }, 1500);

    } catch (error) {
      console.error('Error updating price details:', error);
      setSnackbarMessage('Failed to update price details');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };



  useEffect(() => {
    // console.log("NEW ORDER DATA", orderData);
  }, [orderData])

  const handleMarkAsPaid = async () => {

    console.log("Mark as Paid", orderData.orderID);

    try {
      const confirmed = await showConfirmation("Payment Confirmation", "Are you sure you want to confirm the payment?", "No", "Yes, Confirm");
      if (confirmed) {

        setLoading(true);
        const updatedPayment = await sellerRequest.put(`/order/update-payment/${orderData.orderID}`, {
          payment: {
            status: 'Success',
            remarks: "Payment Marked as Collected"

          }
        });

        setOrderData((prevData) => ({
          ...prevData,
          payment: {
            ...prevData.payment,
            status: 'Success',
            remarks: "Payment Marked as Collected"
          }
        }));
        console.log("Updated Payment", updatedPayment.data);
        setSnackbarMessage('Payment marked as collected');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error marking as paid:', error);
      setSnackbarMessage('Failed to mark payment as collected');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
    }
  }


  return (
    <div className="order">
      {loading && (

        <Backdrop
          open
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

      )}
      {orderData && (
        <>
          <div className="order-header">
            <div className="left">
              <div className="order-header-arrow">
                <button onClick={handleBack} className="back-button">
                  <ArrowBackIcon />
                </button>
              </div>
              <div className="order-header-details">
                <h1>#{orderData.orderID}</h1>
                <h6>{formatDate(orderData.createdAt)}</h6>
              </div>
            </div>
            <div className="right">
              <button className='button-cancel' onClick={handleCancelOrder} >Cancel</button>
              <button className='button-delete' onClick={handleDeleteOrder}>Delete</button>
              <button className='button-duplicate' onClick={handleDuplicateOrder}>Clone</button>
              {/* <ContentCopyIcon/> */}
            </div>

          </div>
          <div className="order-content">
            <div className="order-details-grid">
              <div className="order-products">
                <h1 className="title">Products</h1>
                <TableContainer component={Paper} className="table">
                  <Table sx={{ minWidth: 100 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="table-cell">Product ID</TableCell>
                        <TableCell className="table-cell">Product</TableCell>
                        <TableCell className="table-cell">Quantity</TableCell>
                        <TableCell className="table-cell">Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderData.products.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell className="table-cell">{row.productID}</TableCell>
                          <TableCell className="table-cell">
                            <div className="cell-wrapper">
                              {row.image && (
                                <img src={row.image} alt="" className="image" />
                              ) }
                              {row.productName && row.productName.length > 20
                                ? row.productName.substring(0, 20) + '...'
                                : row.productName || 'Unnamed Product'}
                            </div>
                          </TableCell>
                          <TableCell className="table-cell">{row.qty}</TableCell>
                          <TableCell className="table-cell">₹{row.unitPrice}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {editableStatuses.includes(orderData.status) && (

                  <EditIcon onClick={() => handleEdit('price')} className="editIcon" />
                )}
              </div>

              <div className="order-customer">
                {customerData && (<div className="details">
                  <div className="header">
                    <h1 className="title">Customer Info</h1>
                    <div className="iconContainer">

                      <MailIcon
                        className="icon"
                        onClick={() => window.location.href = `mailto:${customerData.email}`}
                      />
                      <CallIcon
                        className="icon"
                        onClick={() => window.location.href = `tel:${customerData.phone}`}
                      />
                      {/* <BookmarkAddIcon
                        className="icon"
                        onClick={() => downloadVCard(customerData)}
                      /> */}
                      <WhatsAppIcon
                        className="icon"
                        onClick={() => window.open(`https://wa.me/${customerData.phone}`, '_blank')}
                      />

                    </div>
                  </div>

                  <div className="detailItem">
                    <span className="itemKey">Name:</span>
                    <span className="itemValue" >{customerData.name}</span>

                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Email:</span>
                    <span className="itemValue" >{customerData.email}</span>
                  </div>

                  <div className="detailItem">
                    <span className="itemKey">Phone:</span>
                    <span className="itemValue">{customerData.phone}</span>
                  </div>

                  {/* <div className="detailItem">
                    <span className="itemKey">Address:</span>
                    <span className="itemValue" >
                      {customerData.address.street}{", "}
                      {customerData.address.city}{", "}

                      <span className="itemValue">
                        {customerData.address.state}{", "}
                      </span>
                      <span className="itemValue" >
                        {customerData.address.postalCode}
                      </span>
                      <br />
                      <span className="itemValue">{customerData.address.country}</span>
                    </span>
                  </div> */}
                  <div className="detailItem">
                    <span className="itemKey">Address:</span>
                    <span className="itemValue" >
                      {orderData.shippingAddress.fullAddress}{", "}
                      {orderData.shippingAddress.city}{", "}
                      {orderData.shippingAddress.district ? `, ${orderData.shippingAddress.district}` : ''}

                      <span className="itemValue">
                        {orderData.shippingAddress.state}{", "}
                      </span>
                      <span className="itemValue" >
                        {orderData.shippingAddress.pincode}
                      </span>
                      <br />
                      <span className="itemValue">{orderData.shippingAddress.country}</span>
                    </span>
                  </div>

                  {editableStatuses.includes(orderData.status) && (
                    <EditIcon onClick={() => handleEdit('customer')} className="editIcon" />
                  )}
                </div>)}

                {!customerData && (
                  <Box sx={{ width: 300 }}>
                    {/* <Skeleton /> */}
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    {/* <Skeleton animation={true} /> */}
                    <Skeleton variant="rectangular" height={100} />
                  </Box>
                )}

              </div>
            </div>
            <div className="order-details-grid">
              {/* STEPPER */}
              <div className="order-info">
                <h1 className="title">Order Info</h1>
                <div className="stepper-container">
                  <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={getOrderStatusStep(orderData.status)} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </div>
                <div className="detail-item">
                  <span className="item-key">Order ID:</span>
                  <span className="item-value">{orderData.orderID}</span>
                </div>
                <div className="detail-item status">
                  <span className="item-key">Status:</span>
                  <span
                    className="item-value"
                    style={{
                      color:
                        orderData.status === 'RTO' ? 'black' :
                          orderData.status === 'LOST' ? 'red' :
                            orderData.status === 'Delivered' ? 'black' :
                              orderData.status === 'Order Placed' ? 'black' :
                                orderData.status === 'Order Confirmed' ? 'black' :
                                  orderData.status === 'Order Processing' ? 'black' :
                                    orderData.status === 'Manifested' ? 'black' :
                                      orderData.status === 'Not Picked' ? '#ffffff' :
                                        orderData.status === 'Cancelled' ? 'black' :
                                          'black',
                      backgroundColor:
                        orderData.status === 'RTO' ? 'red' :
                          orderData.status === 'LOST' ? 'transparent' :
                            orderData.status === 'Delivered' ? 'lightgoldenrodyellow' :
                              orderData.status === 'Order Placed' ? '#ADD8E6' :
                                orderData.status === 'Order Confirmed' ? '#90EE90' :
                                  orderData.status === 'Order Processing' ? '#FFFFE0' :
                                    orderData.status === 'Manifested' ? '#F08080' :
                                      orderData.status === 'Not Picked' ? 'rgb(255 0 0)' :
                                        orderData.status === 'Shipped' ? '#87CEFA' :
                                          orderData.status === 'Cancelled' ? '#D3D3D3' : // Light Gray
                                            'transparent',
                      borderRadius: '5px',
                      padding: '5px'
                    }}
                  >
                    {orderData.status}
                    {/* <span onClick={() => checkStatus()}><SyncIcon /></span> */}
                  </span>
                </div>


              </div>

              <div className="order-summary">
                <h1 className="title">Price Info</h1>
                <div className="detail-item">
                  <span className="item-key">Amount:</span>
                  <span className="item-value">₹{orderData.amount}</span>
                </div>
                <div className="detail-item">
                  <span className="item-key">Delivery Charge:</span>
                  <span className="item-value">₹{orderData.deliveryCharge}</span>
                </div>
                <div className="detail-item">
                  <span className="item-key">Total:</span>
                  <span className="item-value">₹{orderData.totalOrderValue}</span>
                </div>
                <div className="detail-item">
                  <span className="item-key">Profit:</span>
                  <span className="item-value">₹{orderData.profit}</span>
                </div>
                {editableStatuses.includes(orderData.status) && (
                  <EditIcon onClick={() => handleEdit('price')} className="editIcon" />
                )}
              </div>

              <div className="order-summary">
                <h1 className="title">Payment Info</h1>
                <div className="detail-item"

                >

                  <span className="item-key">Payment Status:</span>
                  <span
                    className="item-value"
                    style={
                      orderData.payment?.status === 'Success' || orderData.payment?.status === 'Failed' || orderData.payment?.status === 'Cancelled'
                        ? {
                          backgroundColor: orderData.payment?.status === 'Success' ? '#2dad2d' : orderData.payment?.status === 'Cancelled' ? 'rgb(255 0 0)' : '#ff0000',
                          color: 'white',
                          padding: '4px 6px',
                          borderRadius: '5px',
                        }
                        : {}
                    }
                  >
                    {orderData.payment?.status || '-'}
                  </span>
                </div>
                <div className="detail-item">
                  <span className="item-key">Payment Method:</span>
                  <span className="item-value">{orderData.payment?.method || '-'}</span>
                </div>
                <div className="detail-item">
                  <span className="item-key">Payment ID:</span>
                  <span className="item-value">{orderData.payment?.paymentID || '-'}</span>
                </div>
                <div className="detail-item">
                  <span className="item-key">Remarks:</span>
                  <span className="item-value">{orderData.payment?.remarks || '-'}</span>
                </div>
                {orderData.payment?.method === 'Pre-paid' && orderData.payment?.status !== 'Success' && (
                  <div className="detail-item">
                    <button className="mark-as-paid-button" onClick={handleMarkAsPaid}>Mark as Paid</button>
                  </div>
                )}
              </div>

              <div className="order-packageInfo">
                <h1 className='title'>Package Information</h1>
                <div className="package-details">
                  <div className="detail-item">
                    <span className="item-key">Length:</span>
                    <span className="item-value">{shipmentDetails?.length || '-'}</span>
                  </div>
                  <div className="detail-item">
                    <span className="item-key">Breadth:</span>
                    <span className="item-value">{shipmentDetails?.breadth || '-'}</span>
                  </div>
                  <div className="detail-item">
                    <span className="item-key">Height:</span>
                    <span className="item-value">{shipmentDetails.height || '-'}</span>
                  </div>
                  <div className="detail-item">
                    <span className="item-key">Weight:</span>
                    <span className="item-value">{shipmentDetails?.weight || '-'}</span>
                  </div>
                  {editableStatuses.includes(orderData.status) && (

                    <EditIcon onClick={() => handleEdit('package')} className="editIcon" />
                  )}
                </div>
              </div>

              <div className="order-shipping-details">
                <div className="header">
                  <h1 className="title">Shipping Info</h1>
                  <div className="iconContainer">

                    <Tooltip title="Copy tracking link" arrow>
                      <LinkIcon
                        className="icon"
                        onClick={handleCopyLink}
                      />
                    </Tooltip>
                    <Tooltip title="Share tracking link via WhatsApp" arrow>
                      <WhatsAppIcon
                        className="icon"
                        onClick={handleWhatsAppShare}
                      />
                    </Tooltip>
                  </div>
                </div>
                <div className="detail-item">
                  <span className="item-key">Shipping Services:</span>
                  <select value={selectedShippingService} onChange={handleShippingServiceChange}>
                    <option value="" disabled>Select a Shipping Service</option>
                    {renderActiveShippingServices()}
                  </select>
                </div>

                {selectedShippingService === 'India Post' && (
                  <div className="detail-item">
                    <span className="item-key">Warehouse:</span>
                    <select value={selectedWarehouseID} onChange={handleWarehouseChange}>
                      <option value="" disabled>Select a warehouse</option>
                      {sellerData.shippingServices.indiaPost.wareHouses.map((warehouse) => (
                        <option key={warehouse._id} value={warehouse._id}>
                          {warehouse.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {selectedShippingService === 'Delhivery' && (
                  <div className="detail-item">
                    <span className="item-key">Warehouse:</span>
                    <select value={selectedWarehouseID} onChange={handleWarehouseChange}>
                      <option value="" disabled>Select a warehouse</option>
                      {sellerData.shippingServices.delhivery.wareHouses.map((warehouse) => (
                        <option key={warehouse._id} value={warehouse._id}>
                          {warehouse.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {selectedShippingService === 'Custom Shipping' && (
                  <div className="detail-item">
                    <span className="item-key">Warehouse:</span>
                    <select value={selectedWarehouseID} onChange={handleWarehouseChange}>
                      <option value="" disabled>Select a warehouse.</option>
                      {sellerData.shippingServices.customShipping.wareHouses.map((warehouse) => (
                        <option key={warehouse._id} value={warehouse._id}>
                          {warehouse.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <div className="detail-item">
                  <span className="item-key">Way Bill No:</span>
                  <span className="item-value">{orderData.waybill ? orderData.waybill : '-'}</span>
                </div>
                <div className="detail-item">
                  <span className="item-key">Shipping Cost:</span>
                  <span className="item-value"> {orderData.shippingCost ? `₹ ${orderData.shippingCost} /-` : '-'}</span>
                </div>

                {warehouseWarning && <span className="warning">{warehouseWarning}</span>}
              </div>
            </div>
            <div className="order-error">
              {errorMessage && <p className="error-label">{errorMessage}</p>}
            </div>

            <div className="order-actions">
              {orderData.status === 'Order Placed' && (
                <button onClick={() => handleUpdateOrderStatus('Order Confirmed')} className="button-primary">Confirm Order</button>

              )}
              {orderData.status === 'Order Confirmed' && (
                <>
                  <button onClick={() => handleUpdateOrderStatus('Order Processing')} className="button-primary">Start Processing</button>
                  <button onClick={() => handleUpdateOrderStatus('Manifested')} className="button-primary">Mark as Manifested</button>
                  {selectedShippingService === "Delhivery" && <button onClick={handleCalculateShippingCost} className="button-primary">Calculate Shipping.</button>}
                </>
              )}
              {orderData.status === 'Order Processing' && (
                <>
                  {selectedShippingService === "Delhivery" && (
                    <>
                      <button onClick={handleCalculateShippingCost} className="button-primary">Calculate Shipping</button>

                      <button onClick={handleShipNow} className="button-primary">Ship Now</button>
                    </>
                  )}

                  {(selectedShippingService === "Custom Shipping" || selectedShippingService === "India Post") && (
                    <>
                      <button onClick={() => handleUpdateOrderStatus('Manifested')} className="button-primary">Mark as Manifested</button>
                    </>
                  )}

                </>
              )}
              {orderData.status === 'Manifested' && (
                <>
                  {selectedShippingService === "Custom Shipping" && (
                    <>
                      <button onClick={() => handleUpdateOrderStatus('Shipped')} className="button-primary">Mark as Shipped</button>
                      <button onClick={() => handlePrintLabel_customShipping()} className="button-primary">Generate Shipping Label</button>

                    </>
                  )}

                </>
              )} {orderData.status === 'Shipped' && (
                <>
                  {selectedShippingService === "Custom Shipping" && (
                    <>
                      <button onClick={() => handleUpdateOrderStatus('Delivered')} className="button-primary">Mark as Delivered</button>
                    </>
                  )}

                </>
              )}
              {orderData.waybill && (
                <>

                  <button onClick={handlePrintLabel_Delhivery} className="button-primary">Print Label</button>

                  <button onClick={handleCheckStatus} className="button-primary">Check Status</button>
                </>
              )}
              {(orderData.status === 'Delivered' || orderData.status === 'Manifested') && (
                <>
                  {/* <button onClick={checkStatus} className="button-primary">Check Status</button> */}
                  {/* <button onClick={schedulePickup} className="button-primary">Schedule Pickup</button> */}
                </>
              )}
            </div>

            <div className="order-timeline">
              <h2 className="timeline-title">Order Timeline</h2>
              <ul className="timeline-list">
                {orderData.orderTimeline.map((entry, index) => (
                  <li key={index} className="timeline-item">
                    <div className="timeline-content">
                      <span className="timeline-date">{new Date(entry.timestamp).toLocaleString()}</span>
                      <p className="timeline-remarks">{entry.remarks}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

          </div>
        </>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>


      {editSection === 'package' && (
        <div className="package-overlay">
          <div className="content">
            <button className="close-button" onClick={() => handleEdit(null)}>
              <CloseIcon />
            </button>
            <h2>Package Details</h2>
            <p>Add dimensions details & weight of the package.</p>

            <div className="input-row">
              <div className="input-group">
                <label>Length</label>
                <input
                  type="text"
                  value={tempShipmentDetails.length}
                  onChange={(e) => handleDimensionChange('length', e)}
                />
              </div>
              <div className="input-group">
                <label>Breadth</label>
                <input
                  type="text"
                  value={tempShipmentDetails.breadth}
                  onChange={(e) => handleDimensionChange('breadth', e)}
                />
              </div>
              <div className="input-group">
                <label>Height</label>
                <input
                  type="text"
                  value={tempShipmentDetails.height}
                  onChange={(e) => handleDimensionChange('height', e)}
                />
              </div>
              <span className="unit">cm</span>
            </div>

            <div className="input-row">
              <div className="input-group full-width">
                <label>Enter Weight</label>
                <input
                  type="text"
                  value={tempShipmentDetails.weight}
                  onChange={handleWeightChange}
                />
                <span className="unit">gm</span>
              </div>
            </div>

            <div className="button-row">
              <button className="cancel-button" onClick={() => handleEdit(null)}>Cancel</button>
              <button className="button-primary" onClick={handleSavePackageDetails}>Save Changes</button>
            </div>
          </div>
        </div>
      )}

      {editSection === 'price' && (
        <div className="price-overlay">
          <div className="content">
            <button className="close-button" onClick={() => handleEdit(null)}>
              <CloseIcon />
            </button>
            <h2>Product & Price Details</h2>
            <p>Edit the product & price details for the order.</p>
            <div className="toggle-collapse" onClick={toggleCollapse}>
              <span>Products</span>
              <button className="toggle-button">
                {isCollapsed ? "▼" : "▲"}
              </button>
            </div>
            {/* New Add Product Button */}


            {/* Add Product Overlay */}
            {showAddProductOverlay && (
              <div className="add-product-overlay">
                <div className="overlay-content">
                  <button className="close-button" onClick={() => setShowAddProductOverlay(false)}>
                    <CloseIcon />
                  </button>
                  <h2>Add Product</h2>
                  <input
                    type="text"
                    placeholder="Search for a product..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <ul className="product-list">
                    {filteredProducts.map(product => (
                      <li key={product.productID} className="product-item">
                        <span>{product.title} ({product.productID})</span>
                        <button onClick={() => handleAddProduct(product)}>Add</button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}


            {!isCollapsed && (
              <div className="product-listing">
                {tempOrderData.products.map((product, index) => (
                  <div className="product-item" key={product._id}>
                    <div className="product-thumbnail">
                      {product.image && (
                        <img src={product.image} alt={product.productName} />
                      )}
                    </div>
                    <div className="product-details">
                      <h4>
                        {product.productName.slice(0, 20)}
                        {product.productName.length > 20 ? "..." : ""}
                      </h4>
                      <div className="inputs">
                        <div className="input-group">
                          <label htmlFor={`quantity-${index}`}>Qty</label>
                          <input
                            id={`quantity-${index}`}
                            type="number"
                            value={tempOrderData.products[index].qty || ''}
                            onChange={(event) => handleProductQuantityChange(index, event)}
                          />
                        </div>

                        <div className="input-group">
                          <label htmlFor={`price-${index}`}>Price</label>
                          <input
                            id={`price-${index}`}
                            type="text"
                            value={product.unitPrice}
                            readOnly
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <button className="add-product-button" onClick={() => {
                  fetchProducts()
                  setShowAddProductOverlay(true)
                }}>
                  Add a Product
                </button>
              </div>

            )}

            <div className="input-row">
              <div className="input-group full-width">
                <label htmlFor="amount">Amount</label>
                <input
                  id="amount"
                  type="text"
                  value={tempOrderData.amount || ''}
                  readOnly
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-group full-width">
                <label htmlFor="deliveryCharge">Delivery Charge</label>
                <input
                  id="deliveryCharge"
                  type="number"
                  value={tempOrderData.deliveryCharge}
                  onChange={(e) => handlePriceChange('deliveryCharge', e)}
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-group full-width">
                <label htmlFor="totalOrderValue">Total</label>
                <input
                  id="totalOrderValue"
                  type="text"
                  value={tempOrderData.totalOrderValue || ''}
                  readOnly
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-group full-width">
                <label htmlFor="profit">Profit</label>
                <input
                  id="profit"
                  type="text"
                  value={tempOrderData.profit || ''}
                  onChange={(e) => handlePriceChange('profit', e)}
                />
              </div>
            </div>

            <div className="button-row">
              <button className="cancel-button" onClick={() => handleEdit(null)}>Cancel</button>
              <button className="button-primary" onClick={handleSavePriceDetails}>Save Changes</button>
            </div>
          </div>
        </div>
      )}

      {editSection === 'customer' && (
        <div className="customer-overlay">
          <div className="content">
            <button className="close-button" onClick={() => handleEdit(null)}>
              <CloseIcon />
            </button>
            <h2>Customer Information</h2>
            <p>Edit the customer information for the order.</p>

            <div className="input-row">
              <div className="input-group full-width">
                <label>Name</label>
                <input
                  type="text"
                  value={tempCustomerData.name}
                  onChange={(e) => handleCustomerChange('name', e.target.value)}
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-group full-width">
                <label>Email</label>
                <input
                  type="text"
                  value={tempCustomerData.email}
                  onChange={(e) => handleCustomerChange('email', e.target.value)}
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-group full-width">
                <label>Phone</label>
                <input
                  type="text"
                  value={tempCustomerData.phone}
                  onChange={(e) => handleCustomerChange('phone', e.target.value)}
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-group full-width">
                <label>Street</label>
                <input
                  type="text"
                  value={tempOrderData.shippingAddress.fullAddress}
                  onChange={(e) => handleOrderDataChange('fullAddress', e.target.value)}
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-group full-width">
                <label>City</label>
                <input
                  type="text"
                  value={tempOrderData.shippingAddress.city}
                  onChange={(e) => handleOrderDataChange('city', e.target.value)}
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-group full-width">
                <label>State</label>
                <input
                  type="text"
                  value={tempOrderData.shippingAddress.state}
                  onChange={(e) => handleOrderDataChange('state', e.target.value)}
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-group full-width">
                <label>PIN Code</label>
                <input
                  type="text"
                  value={tempOrderData.shippingAddress.pincode}
                  onChange={(e) => handleOrderDataChange('pincode', e.target.value)}
                />
              </div>
            </div>

            <div className="input-row">
              <div className="input-group full-width">
                <label>Country</label>
                <input
                  type="text"
                  value={tempOrderData.shippingAddress.country}
                  onChange={(e) => handleOrderDataChange('country', e.target.value)}
                />
              </div>
            </div>

            <div className="button-row">
              <button className="cancel-button" onClick={() => {

                setTempCustomerData(customerData)
                setTempOrderData(orderData);
                // setTempCustomerData((prevDetails) => ({
                //   ...prevDetails,
                //   customerID: customerData.customerID,
                //   name: customerData.name,
                //   email: customerData.email,
                //   phone: customerData.phone,
                //   shippingAddress: {
                //     street: orderData.shippingAddress.fullAddress,
                //     city: orderData.shippingAddress.city,
                //     state: orderData.shippingAddress.state,
                //     pincode: orderData.shippingAddress.pincode,
                //     country: orderData.shippingAddress.country,

                //   }

                // }));
                handleEdit(null)
              }}>Cancel</button>
              <button className="button-primary" onClick={handleSaveCustomerDetails}>Save Changes</button>
            </div>
          </div>
        </div>

      )}

      {isConfirmationVisible && (
        <ConfirmationBox
          heading={confirmationAction.heading}
          content={confirmationAction.content}
          onConfirm={confirmationAction.onConfirm}
          onCancel={confirmationAction.onCancel}
          cancelLabel={confirmationAction.cancelLabel}
          confirmLabel={confirmationAction.confirmLabel}
        />
      )}


    </div >
  );
};

export default Order;
