import { changeUsernameIndex, loginFailure, loginStart, loginSuccess, updateSellerData, updateSubscriptionData, updateWallet } from "./sellerRedux";
import { publicRequest, sellerRequest } from "../requestMethods";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  let response = {};
  try {
    const res = await publicRequest.post("/auth/login", user, {
      withCredentials: true // Send cookies with the request
    });

    if (res) {
      try {
        const subscriptionData = await sellerRequest.get(`/subscription/get-subscription/${res.data.sellerID}`, {
          withCredentials: true,
        });
        // console.log("subscription data API Call", subscriptionData.data);
        dispatch(updateSubscriptionData({ subscriptionData: subscriptionData.data }));

        const walletData = await sellerRequest.get(`/wallet/${res.data.sellerID}/balance`, {
          withCredentials: true,
        });

        // console.log("wallet Data", walletData.data);

        dispatch(updateWallet(walletData.data.balance));


      } catch (error) {
        if (error.response && error.response.status === 404) {
          // console.log("No subscription found, redirecting to plan selection.");
          // Handle the case where no subscription is found
          dispatch(updateSubscriptionData({ subscriptionData: null }));
        } else {
          throw error; // Re-throw if it's not a 404 error
        }
      }

      dispatch(loginSuccess({ sellerData: res.data }));
      response = { status: "success", message: "" };
    }
  } catch (err) {
    dispatch(loginFailure());
    response = { status: "error", message: "Login failed" };
  }
  return response;
};


export const fetchAndUpdateSellerData = ({ sellerID }) => async (dispatch) => {
  // console.log("Fetch and update seller data:REDUX");
  try {
    const res = await sellerRequest.get(`/seller/find/${sellerID}`, {
      withCredentials: true,
    });
    const subscriptionData = await sellerRequest.get(`/subscription/get-subscription/${sellerID}`, {
      withCredentials: true,
    });

    // const walletData = await sellerRequest.get(`/wallet/${sellerID}/balance`, {
    //   withCredentials: true,
    // });

    // console.log("subscription data API Call", subscriptionData.data);


    // console.log("Fetched data:", res.data);
    dispatch(updateSellerData({ sellerData: res.data }));
    dispatch(updateSubscriptionData(subscriptionData.data));


    // console.log("wallet Data", walletData.data);
    // // dispatch(updateWallet(walletData.data));

  } catch (error) {
    console.error("Error fetching seller data:", error);
    // Handle error if needed
  }
};

export const fetchAndUpdateWalletData = ({ sellerID }) => async (dispatch) => {
  // console.log("123 fetch wallet with redux");

  try {
    const walletData = await sellerRequest.get(`/wallet/${sellerID}/balance`, {
      withCredentials: true,
    });

    // console.log("wallet Data", walletData.data);

    // dispatch(updateWallet(walletData.data));

  } catch (error) {
    console.error("Error fetching seller data:", error);
  }
};

export const decodeToken = (accessToken) => {
  // console.log("1");
  try {
    const decodedToken = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    // console.log("Decoded token",decodedToken);

    if (decodedToken.exp < currentTime) {
      return null; // Token has expired
    }

    return decodedToken;
  } catch (error) {
    return null; // Invalid token
  }
};

