import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Snackbar, Alert, Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import './Domain.scss';
import { sellerRequest } from '../../requestMethods';

const DomainForm = () => {
    const [customDomain, setCustomDomain] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [domainData, setDomainData] = useState();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [adding, setAdding] = useState(false);
    const [domains, setDomains] = useState([]);
    const [newDomain, setNewDomain] = useState('');
    const [selectedDomain, setSelectedDomain] = useState(null);

    const sellerID = useSelector((state) => state.seller.sellerData.sellerID);
    const sanitizeDomain = (domain) => {
        // Remove protocol and any unwanted characters
        return domain.replace(/^(https?:\/\/)?(www\.)?/, '').trim();
    };
    const handleAddDomain = async (e) => {

        const sanitizedDomain = sanitizeDomain(newDomain);

        if (!sanitizedDomain) {
            setError('Please enter a valid domain.');
            return;
        }
        e.preventDefault();
        setLoading(true);
        setStatusMessage('');

        try {
            const response = await sellerRequest.post(`/domain/add-domain/${sellerID}`, { customDomain: sanitizedDomain });
            setStatusMessage('Domain added successfully!');
            console.log("response", response.data);
        } catch (error) {
            setStatusMessage('Error adding domain: ' + error.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const handleDomainClick = (domain) => {
        setSelectedDomain(domain);
    };


    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const response = await sellerRequest.get(`/seller/${sellerID}/domain`);
                console.log("response", response.data);
                setDomainData(response.data);
                setDomains(response.data);

            } catch (err) {
                console.log("error", err);
                // setError('Error fetching domains: ' + (err.response?.data?.message || err.message));
            } finally {
                setLoading(false);
            }
        };

        fetchDomains();
    }, [sellerID]);

    return (
        <div className="seller-domain-manager">
            <h2 className="seller-domain-manager__title">Your Domains</h2>
            {domains.length > 0 ? (
                <ul>
                    {domains.map((domain) => (
                        <li key={domain._id} onClick={() => handleDomainClick(domain)}>
                            {domain.customDomain} - Status: {domain.status}
                        </li>
                    ))}
                </ul>
            ) : (
                <div className="seller-domain-manager__input-container">
                    <p>No domains found. Please add a new domain.</p>
                    <input
                        className="seller-domain-manager__input-container-input"
                        type="text"
                        value={newDomain}
                        onChange={(e) => setNewDomain(e.target.value)}
                        placeholder="Enter your custom domain"
                    />
                    <button
                        className="seller-domain-manager__input-container-button"
                        onClick={handleAddDomain}
                        disabled={adding}
                    >
                        {adding ? 'Adding...' : 'Add Domain'}
                    </button>
                </div>
            )}
            {selectedDomain && (
                <div>
                    <h3>Domain Details</h3>
                    <p><strong>Custom Domain:</strong> {selectedDomain.customDomain}</p>
                    <p><strong>CNAME Record:</strong> {selectedDomain.cnameRecord}</p>
                    <p><strong>Status:</strong> {selectedDomain.status}</p>
                    <p><strong>Created At:</strong> {new Date(selectedDomain.createdAt).toLocaleString()}</p>
                    <p><strong>Updated At:</strong> {new Date(selectedDomain.updatedAt).toLocaleString()}</p>
                </div>
            )}
            {error && <p className="seller-domain-manager__error-message">{error}</p>}
        </div>
    );
};

export default DomainForm;